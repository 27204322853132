import React, { Fragment, useState } from "react";
import { get, isEmpty } from "src/utils";
import extractMarketoIdAndSerial from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import { ResourcesHero as Hero, EventYouMayLike } from "src/components/templateComponents";
import usePrependBaseURLIntoImages from "src/components/helpers/usePrependBaseURLIntoImages";
import timeFormatter from "../../helpers/timeFormatter";
import cn from "classnames";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import loadable from '@loadable/component';
import { SEO, ShareFollowBox } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
const EventStickyDiv = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.EventStickyDiv })
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
const Form = loadable(() => import('src/components/resources/ResourceForm'))
const IndividualProfileCard = loadable(() => import('src/components/commonComponents'), 
{ resolveComponent: (components) => components.IndividualProfileCard })
const LeadershipProfileCard = loadable(() => import('src/components/commonComponents'), 
{ resolveComponent: (components) => components.LeadershipProfileCard })

const getImageObject = require("src/../helpers/getImageObject")

const EventDetailTemplate = ({ pageContext, data, path }) => {
  let timeZone = 'CST'
  const entLabel = pageContext?.relationships?.eventType?.name
  const locale = pageContext?.locale;
  let heroImage = get(pageContext, "relationships.fieldMediaImage");
  heroImage = getImageObject(heroImage, {}, "fieldMediaImage");
  let startDate = null;
  // let startTime = null;
  let startDateFormated = null;
  let startDateTime = null;
  let endDate = null;
  let endDateFormated = null;
  // let endTime = null;
  let endDateTime = null;
  if (!isEmpty(pageContext?.startEndTimes?.startDate)) {
    startDate = new Date(pageContext?.startEndTimes?.startDate.replace(/-/g, '/'));
    let options = pageContext.startEndTimes?.endDate ? pageContext.startEndTimes?.endDate !== pageContext.startEndTimes?.startDate ? {
      month: "long",
      day: "numeric",
    } : {
      month: "long",
      day: "numeric", year: "numeric"
    } : {
      month: "long",
      day: "numeric", year: "numeric"
    }
    startDateFormated = startDate.toLocaleString("en-US", options);
  }
  if (pageContext.fieldStartEndTimes) {
    let startEnd = timeFormatter(pageContext.fieldStartEndTimes)
    startDateTime = startEnd.startTime
    endDateTime = startEnd.endTime
  }

  if (!isEmpty(pageContext?.startEndTimes?.endDate)) {
    if (pageContext?.startEndTimes?.endDate !== pageContext?.startEndTimes?.startDate) {
      endDate = new Date(pageContext?.startEndTimes?.endDate.replace(/-/g, '/'));
      endDateFormated = endDate.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      endDateTime = null;
    }

  }
  if (!isEmpty(pageContext?.fieldStartEndTimes?.value) && !isEmpty(pageContext?.startEndTimes?.value) && !isEmpty(pageContext?.fieldTimeZone)) {
    let value = pageContext?.startEndTimes?.value
    timeZone = getTimeZoneAbbreviation(value, pageContext.fieldTimeZone);
  }

  usePrependBaseURLIntoImages(["event-detail-body"]);
  const address = pageContext.address;

  // const heroPreHeading = `${startDateFormated} - ${endDateFormated}`;
  const isVirtualEvent = pageContext.fieldVirtualEvent;

  let heroSubHeading = "";
  if (!isEmpty(address?.locality)) {
    heroSubHeading = heroSubHeading.concat(address.locality);
  }

  if (!isEmpty(address?.administrativeArea)) {
    if (!isEmpty(address?.locality)) {
      heroSubHeading = heroSubHeading.concat(", ");
    }
    heroSubHeading = heroSubHeading.concat(address.administrativeArea);
  } else if (
    isEmpty(address?.administrativeArea) &&
    !isEmpty(address?.countryCode)
  ) {
    // Many countries outside of the US and Canada do not have administrative Areas and
    // return null values (EX: Great Britain), so use countryCode instead
    if (!isEmpty(address?.locality)) {
      heroSubHeading = heroSubHeading.concat(", ");
    }
    heroSubHeading = heroSubHeading.concat(address.countryCode);
  }

  if (!isEmpty(pageContext?.fieldVenue)) {
    if (!isEmpty(address?.locality) || !isEmpty(address?.administrativeArea) || !isEmpty(address?.countryCode)) {
      heroSubHeading = heroSubHeading.concat(" | ");
    }
    heroSubHeading = heroSubHeading.concat(pageContext.fieldVenue);
  }
  if (!isEmpty(pageContext?.fieldBoothOrTableId?.toString())) {
    if (!isEmpty(address.locality) || !isEmpty(address?.administrativeArea) || !isEmpty(address?.countryCode) || !isEmpty(pageContext?.fieldVenue)) {
      heroSubHeading = heroSubHeading.concat(" | ");
    }
    heroSubHeading = heroSubHeading.concat("BOOTH " + pageContext.fieldBoothOrTableId);
  }

  if (isVirtualEvent) {
    heroSubHeading = "Virtual";
  }


  // speakers

  let leadershipData = [];

  const person = {
    id: "",
    name: "",
    title: "",
    image: "",
    altTag: "",
    sortOrder: 50,
    biography: <Fragment></Fragment>,
  };

  pageContext.relationships?.speakers?.forEach((item) => {
    if (item) {
      let personObject = Object.create(person)
      personObject.id = item.nid
      personObject.name = item.title
      personObject.title = item.position
      personObject.company = item.company
      personObject.nid = item.nid
      personObject.biography = item.body?.value
      personObject.image = getImageObject(item.relationships?.speakerHeadshot, item.speakerHeadshot)
      leadershipData.push(personObject)
    }
  })

  const [selectedCard, setSelectedCard] = useState(null);

  const onSelectCard = (name) => {
    const filteredCard = leadershipData.filter((card) => card.name === name);
    setSelectedCard(filteredCard[0]);
  };

  // form
  const formEntity = pageContext?.relationships?.fieldMarketoFormNode

  let id = null;
  let serial = null;
  let gaLabel = null;
  const fieldMarketoFormEmbedCode = get(
    formEntity,
    "fieldEmbedCode"
  );

  const fieldMarketoFormTitle = get(formEntity, "fieldFormHeader");

  if (
    fieldMarketoFormEmbedCode &&
    fieldMarketoFormEmbedCode.includes("marketo")
  ) {
    id = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).id;
    serial = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).serial;
    gaLabel = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).gaLabel;
  }

  const [isFormLoaded, setFormLoaded] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
  }
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);

  const eventLogoImage = pageContext?.relationships?.fieldEventLogo?.[0] ? getImageObject(pageContext?.relationships?.fieldEventLogo?.[0]?.gatsbyImage, pageContext?.fieldEventLogo?.[0]) : null;
  const OGMarkup = getOGMarkup(pageContext.entityLabel, pageContext.type?.id, { gatsbyImage: heroImage?.shareImage });
  const { entityMetatags: metaTags } = pageContext;

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if (hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  return (
    <>
      <SEO
        lang={locale}
        title={pageContext.entityLabel}
        pageUrl={pageContext.entityUrl.path}
        meta={metaTags}
        OGMarkup={OGMarkup}
        alternatives={hreflangAlternatives}
        heroImage={heroImage}
        preloads={[pageDataPath]}
      />
      <div className="new-resources-page event-page-wrapper">
        <div className="md:tw-pt-[24px] resources-page webinar-page full-header-bg content-visibility-auto-mobile">
          <Hero
            type={"banner"}
            contentType={`${entLabel}`}
            heroImage={heroImage}
            heading={pageContext.entityLabel}
            subTitle={heroSubHeading}
            overViewText={`All ${entLabel}`}
            fromEvent={true}
            body={pageContext?.fieldResourceDescription}
            startDateFormated={startDateFormated}
            endDateFormated={endDateFormated}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            overviewLink={`${pageContext?.locale === 'en-gb' ? '/uk' : ''}/events#${entLabel
              ?.toLowerCase()
              .replace(" ", "-")}`}
            timeZone={timeZone}
            checkForOnDemand={false}
          />

          <div className="container form-container">
            <section className="medium-wrapper">
              <div className="tw-columns medium-container">
                <div className="tw-column  mt-3 left-wrapper is-12-mobile">
                  <div>

                    {pageContext.body && (
                      <div
                        className="single-resource-content-body event-detail-body dangerously-html-handle"
                        dangerouslySetInnerHTML={{ __html: pageContext.body.value }}
                      ></div>
                    )}
                    {pageContext.relationships?.speakers && pageContext.relationships?.speakers.length !== 0 && (
                      <>
                        <div className="section resources-speaker" id="arrow-down-focus">
                          <div className="container">
                            <div className="tw-columns">
                              <div className="tw-column">
                                <h3 className="title title-3 has-text-centered">
                                  Speakers
                                </h3>
                              </div>
                            </div>
                            {selectedCard && (
                              <IndividualProfileCard
                                name={selectedCard.name}
                                title={selectedCard.title}
                                company={selectedCard.company}
                                image={selectedCard.image}
                                altTag={selectedCard.altTag}
                                bio={selectedCard.biography}
                                key={selectedCard.id}
                                setSelectedCard={setSelectedCard}
                              />
                            )}
                            <div className="tw-columns is-multiline is-mobile leadership-tiles is-centered">
                              {leadershipData
                                .filter(({ name }) => name !== selectedCard?.name)
                                .map((card, i) => (
                                  <LeadershipProfileCard
                                    nid={card.nid}
                                    key={`leadership__profile__${card.id}`}
                                    name={card.name}
                                    title={card.title}
                                    company={card.company}
                                    bio={card.biography}
                                    image={card.image}
                                    onSelectCard={onSelectCard}
                                    tabIndex={i}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                  </div>
                  <div className="tw-mt-[52px] lg:tw-mt-[60px]">
                    <ShareFollowBox />
                  </div>
                </div>
                <div className="tw-column right-wrapper is-12-mobile form-right-wrapper">
                  <div className="right-content event-detail-sticky">

                    {(!formTag && pageContext?.relationships?.fieldEventLogo?.length === 0 && !pageContext?.fieldHeroCtaUrl && !pageContext?.fieldHeroCtaTitle) ? null :
                      <StickyBox>
                        <div className={cn({ "right-box": formTag ? isFormLoaded : true })}>
                          <div className="border-box mb-0"></div>
                          <div className="righ-min-scroll">
                            {formTag ?
                              <Form
                                formTag={formTag}
                                title={fieldMarketoFormTitle}
                                isFormLoaded={isFormLoaded}
                              /> :
                              <EventStickyDiv fieldEventLogo={eventLogoImage} fieldHeroCtaUrl={pageContext?.fieldHeroCtaUrl} fieldHeroCtaTitle={pageContext?.fieldHeroCtaTitle} fieldHeroCtaDescription={pageContext?.fieldHeroCtaDescription} />}
                          </div>
                          <div className="border-box"></div>
                        </div>
                      </StickyBox>}

                  </div>
                </div>
              </div>
            </section>
            <section className="resource-secondary-content"></section>
            {pageContext?.fieldShowYouMayAlsoLikeSec && <EventYouMayLike locale={locale} />}
          </div>
        </div>
      </div>
    </>
  );
};


export default EventDetailTemplate;